import { Button, Spinner, Dialog, H2, Text, EditableText, Checkbox, Toaster, Intent } from '@blueprintjs/core';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { loginData } from '../redux';
import { DateInput } from "@blueprintjs/datetime";
import { Select } from "@blueprintjs/select";
import moment from "moment";
import { INTENT_DANGER, INTENT_SUCCESS, POSITION_TOP } from '@blueprintjs/core/lib/esm/common/classes';


function getMomentFormatter(format) {
  // note that locale argument comes from locale prop and may be undefined
  return {
      formatDate: (date, locale) => {
          const d = moment(date);
          d.locale(locale)
        return d.format(format);
      },
      parseDate: (str, locale) => {
        if (!str) return new Date();

        const d = moment(str, format)
        d.locale(locale)
        
        return d.toDate()
      },
      placeholder: format,
  }
};

function dateAux(fecha) {
    let d = fecha.split('/');
    return new Date(d[2], d[1]-1, d[0]);
}

const minYear = new Date('1/1/1900');

  
const today = new Date().toLocaleDateString('es-ES');

function Form(props) {

    const [dialogBorrar, setDialogBorrar] = useState(false);

    const [loading, setLoading] = useState(false);
    const [aseguradoras, setAseguradoras] = useState([]);
    const [id, setId] = useState();
    const [nombre, set_nombre] = useState('');
    const [dni, set_dni] = useState('');
    const [provincia, set_provincia] = useState('');
    const [email, set_email] = useState('');
    const [observaciones, set_observaciones] = useState('');
    const [anteriorDocumento, set_anteriorDocumento] = useState({});
    const [nonpos_edad, set_nonpos_edad] = useState(null);
    const [nonpos_asegSelect, set_nonpos_asegSelect] = useState('');
    const [nonpos_sexo, set_nonpos_sexo] = useState('Hombre');
    const [notasAnteriores, set_notasAnteriores] = useState('');
    const [farmacos, set_farmacos] = useState('');
    const [ocupacion, set_ocupacion] = useState('');
    const [telefono, set_telefono] = useState('');
    const [cb_tabaco, set_cb_tabaco] = useState(false);
    const [cb_alcohol, set_cb_alcohol] = useState(false);
    const [cb_alergias, set_cb_alergias] = useState(false);
    const [cb_hta, set_cb_hta] = useState(false);
    const [cb_diabetes, set_cb_diabetes] = useState(false);
    const [cb_dislipemia, set_cb_dislipemia] = useState(false);
    const [cb_cir, set_cb_cir] = useState(false);
    const [cb_antecdigestivos, set_cb_antecdigestivos] = useState(false);
    const [cb_antecmedicos, set_cb_antecmedicos] = useState(false);
    const [tb_tabaco, set_tb_tabaco] = useState('');
    const [tb_alcohol, set_tb_alcohol] = useState('');
    const [tb_alergias1, set_tb_alergias1] = useState('');
    const [tb_alergias2, set_tb_alergias2] = useState('');
    const [tb_hta, set_tb_hta] = useState('');
    const [tb_diabetes, set_tb_diabetes] = useState('');
    const [tb_dislipemia, set_tb_dislipemia] = useState('');
    const [tb_cir1, set_tb_cir1] = useState('');
    const [tb_cir2, set_tb_cir2] = useState('');
    const [tb_cir3, set_tb_cir3] = useState('');
    const [tb_cir4, set_tb_cir4] = useState('');
    const [tb_cir5, set_tb_cir5] = useState('');
    const [tb_antecdigestivos, set_tb_antecdigestivos] = useState('');
    const [tb_antecdigestivos2, set_tb_antecdigestivos2] = useState('');
    const [tb_antecdigestivos3, set_tb_antecdigestivos3] = useState('');
    const [tb_antecmedicos, set_tb_antecmedicos] = useState('');
    const [tb_antecmedicos2, set_tb_antecmedicos2] = useState('');
    const [tb_antecmedicos3, set_tb_antecmedicos3] = useState('');
    const [tb_antecmedicos4, set_tb_antecmedicos4] = useState('');
    const [tb_antecmedicos5, set_tb_antecmedicos5] = useState('');
    const [address, set_address] = useState('');
    const [alta, set_alta] = useState(new Date());
    const [localidad, set_localidad] = useState('');
    const [tb_dm, set_tb_dm] = useState('');
    const [cb_dm, set_cb_dm] = useState(false);
    const [arrayNotas, set_arrayNotas] = useState([]);

    const deletePaciente = (idPaciente) => {
        axios.delete('https://backend.doctorurquijo.com/pacientes/' + idPaciente,{
            headers: {
                Authorization: 'Bearer ' + loginData.getState().jwt
            }
        }).then(asegs => {
            setDialogBorrar(false);
            Toaster.create({position: POSITION_TOP}).show({ message: "Paciente borrado con éxito.", intent: Intent.SUCCESS });
            props.afterDelete();
        }).catch(e => console.log(e));
    }
    

    useEffect(() => {
        axios.get('https://backend.doctorurquijo.com/aseguradoras/',{
            headers: {
                Authorization: 'Bearer ' + loginData.getState().jwt
            }
        }).then(asegs => {
          setAseguradoras(asegs.data.sort((a, b) => a.nombre.localeCompare(b.nombre)))
        }).catch(e => console.log(e));
        axios.get('https://backend.doctorurquijo.com/pacientes/'+props.id, {
            headers: {
                Authorization: 'Bearer ' + loginData.getState().jwt
            }
        }).then(result => {
            let rw = result.data;
            setId(rw.id);
            set_nombre(rw.nombre || '');
            set_dni(rw.dni || '');
            set_provincia(rw.provincia || '');
            set_email(rw.email || '');
            set_observaciones(rw.observaciones || '');
            set_anteriorDocumento(rw.anteriorDocumento || {});
            set_nonpos_edad(rw.nonpos_edad ? moment(new Date(rw.nonpos_edad)) : null);
            set_nonpos_asegSelect(rw.nonpos_asegSelect || 'PRIVADO');
            set_nonpos_sexo(rw.nonpos_sexo !== 'Seleccionar...' ? rw.nonpos_sexo : 'Hombre');
            set_notasAnteriores(rw.notasAnteriores || '');
            set_farmacos(rw.farmacos || '');
            set_ocupacion(rw.ocupacion || '');
            set_telefono(rw.telefono || '');
            set_cb_tabaco(rw.cb_tabaco || false);
            set_cb_alcohol(rw.cb_alcohol || false);
            set_cb_alergias(rw.cb_alergias || false);
            set_cb_hta(rw.cb_hta || false);
            set_cb_diabetes(rw.cb_diabetes || false);
            set_cb_dislipemia(rw.cb_dislipemia || false);
            set_cb_cir(rw.cb_cir || false);
            set_cb_antecdigestivos(rw.cb_antecdigestivos || false);
            set_cb_antecmedicos(rw.cb_antecmedicos || false);
            set_tb_tabaco(rw.tb_tabaco || '');
            set_tb_alcohol(rw.tb_alcohol || '');
            set_tb_alergias1(rw.tb_alergias1 || '');
            set_tb_alergias2(rw.tb_alergias2 || '');
            set_tb_hta(rw.tb_hta || '');
            set_tb_diabetes(rw.tb_diabetes || '');
            set_tb_dislipemia(rw.tb_dislipemia || '');
            set_tb_cir1(rw.tb_cir1 || '');
            set_tb_cir2(rw.tb_cir2 || '');
            set_tb_cir3(rw.tb_cir3 || '');
            set_tb_cir4(rw.tb_cir4 || '');
            set_tb_cir5(rw.tb_cir5 || '');
            set_tb_antecdigestivos(rw.tb_antecdigestivos || '');
            set_tb_antecdigestivos2(rw.tb_antecdigestivos2 || '');
            set_tb_antecdigestivos3(rw.tb_antecdigestivos3 || '');
            set_tb_antecmedicos(rw.tb_antecmedicos || '');
            set_tb_antecmedicos2(rw.tb_antecmedicos2 || '');
            set_tb_antecmedicos3(rw.tb_antecmedicos3 || '');
            set_tb_antecmedicos4(rw.tb_antecmedicos4 || '');
            set_tb_antecmedicos5(rw.tb_antecmedicos5 || '');
            set_address(rw.address || '');
            set_alta(rw.alta || new Date());
            set_localidad(rw.localidad || '');
            set_tb_dm(rw.tb_dm || '');
            set_cb_dm(rw.cb_dm || false);
            set_arrayNotas(rw.arrayNotas || []);
            setLoading(false);
        }).catch(e => {
            setLoading(false);
            console.log(e)
        });
    }, []);
    

    if(loading)
    return(
        <Spinner/>
    );

    function guardar() {
        axios.put('https://backend.doctorurquijo.com/pacientes/'+props.id, 
        {
            nombre: nombre,
            dni: dni,
            provincia: provincia,
            email: email,
            observaciones: observaciones,
            anteriorDocumento: JSON.stringify(anteriorDocumento),
            nonpos_edad: nonpos_edad,
            nonpos_asegSelect: nonpos_asegSelect,
            nonpos_sexo: nonpos_sexo,
            notasAnteriores: notasAnteriores,
            farmacos: farmacos,
            ocupacion: ocupacion,
            telefono: telefono,
            cb_tabaco: cb_tabaco,
            cb_alcohol: cb_alcohol,
            cb_alergias: cb_alergias,
            cb_hta: cb_hta,
            cb_diabetes: cb_diabetes,
            cb_dislipemia: cb_dislipemia,
            cb_cir: cb_cir,
            cb_antecdigestivos: cb_antecdigestivos,
            cb_antecmedicos: cb_antecmedicos,
            tb_tabaco: tb_tabaco,
            tb_alcohol: tb_alcohol,
            tb_alergias1: tb_alergias1,
            tb_alergias2: tb_alergias2,
            tb_hta: tb_hta,
            tb_diabetes: tb_diabetes,
            tb_dislipemia: tb_dislipemia,
            tb_cir1: tb_cir1,
            tb_cir2: tb_cir2,
            tb_cir3: tb_cir3,
            tb_cir4: tb_cir4,
            tb_cir5: tb_cir5,
            tb_antecdigestivos: tb_antecdigestivos,
            tb_antecdigestivos2: tb_antecdigestivos2,
            tb_antecdigestivos3: tb_antecdigestivos3,
            tb_antecmedicos: tb_antecmedicos,
            tb_antecmedicos2: tb_antecmedicos2,
            tb_antecmedicos3: tb_antecmedicos3,
            tb_antecmedicos4: tb_antecmedicos4,
            tb_antecmedicos5: tb_antecmedicos5,
            address: address,
            alta: alta,
            localidad: localidad,
            tb_dm: tb_dm,
            cb_dm: cb_dm,
            arrayNotas: arrayNotas,
        },
        {
            headers: {
                Authorization: 'Bearer ' + loginData.getState().jwt
            }
        }).then(res => {
            Toaster.create({position: POSITION_TOP}).show({ message: "Ficha actualizada con éxito.", intent: Intent.SUCCESS });
          }).catch(e => alert(e))  
    }

    return(
        <div className='w-100 pa2 relative'>
        <div className='flex flex-row'>
          <div className='w-50'>
            <div className='mb2'>{props.close}</div>
            <div className='flex flex-row'>
              <H2><EditableText value={nombre} placeholder='Nombre' className='os-reg' onChange={e=> set_nombre(e)}/></H2>
              
              <Button className='ml2' minimal large text='Guardar' rightIcon='floppy-disk' onClick={() => guardar()}/>
              
              {/* {props.user === 'jurquijo' && <Button className='ml2' text='Borrar' minimal large rightIcon='trash' onClick={() => {
                props.delete()
              }}/>} */}

                
            </div>
            <div className='flex flex-row items-center mr2'>
              {/* <p className='os-light f6 force-selectable mr4'>ID: {props.id}</p> */}
              <Text className='mr2 os-bold'>DNI:</Text>
              <EditableText value={dni} placeholder='DNI' className='os-reg mr2' onChange={e=> set_dni(e)}/>
              <p className='os-light f6 force-selectable'>Fecha de alta: {anteriorDocumento && anteriorDocumento.fechaAlta ? anteriorDocumento.fechaAlta : (alta ? new Date(alta).toLocaleDateString('es-ES') : 'sin datos.')}</p>
            </div>
            <div className='flex flex-row items-center'>
              <Text className='pr2 os-bold'>Dirección:</Text>
              <EditableText value={address} placeholder='Dirección' className='os-reg mv2 mr4' onChange={e=> set_address(e)}/>
            </div>
            <div className='flex flex-row items-center'>
              <Text className='mr2 os-bold'>Localidad:</Text>
              <EditableText value={localidad} placeholder='Localidad' className='os-reg mv2' onChange={e=> set_localidad(e)}/>
              <Text className='ml2 mr2 os-bold'>Provincia:</Text>
              <EditableText value={provincia} className='mr4 ml4' placeholder='Provincia' className='os-reg mv2' onChange={e=> set_provincia(e)}/>
            </div>
            
            <div className='flex flex-row items-center'>
              <Text className='pr2 os-bold'>Fecha de nacimiento:</Text>
              <DateInput
                dayPickerProps={{firstDayOfWeek: 1, locale: 'es', weekdaysShort: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
                weekdaysLong: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
                months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
              }} 
              {...getMomentFormatter('DD/MM/YYYY')} minDate={minYear} value={nonpos_edad ? nonpos_edad.toDate() : null} className='os-reg mv2' onChange={e=> {
                if (e && moment(e).format('DD/MM/YYYY') !== moment().format('DD/MM/YYYY')) {
                  set_nonpos_edad(moment(e))
                } else {
                  set_nonpos_edad(null)
                }
              }}/>
              <Text className='pr2 os-light ml2'>
                {
nonpos_edad ? new Date().getFullYear() - nonpos_edad.year() : 0
              } años</Text>
            </div>
              <div className='flex flex-row'>
                <div className='flex flex-row items-center'>
                  <Text className='pr2 os-bold'>Aseguradora:</Text>
                  <select value={nonpos_asegSelect} onChange={(e) => set_nonpos_asegSelect(e.target.value)}>
                      {aseguradoras.map((aseg, index) => <option key={index} value={aseg.nombre}>{aseg.nombre}</option>)}
                  </select>
                  
                </div>
              </div>

              <div className='flex flex-row'>
                <div className='flex flex-row items-center mr2'>
                  <Text className='pr2 os-bold'>Teléfono:</Text>
                  <EditableText value={telefono} placeholder='Teléfono' className='os-reg mv2' onChange={e=> set_telefono(e)}/>
                </div>
                
                <div className='flex flex-row items-center'>
                  <Text className='pr2 os-bold'>Ocupación:</Text>
                  <EditableText value={ocupacion} placeholder='Ocupación' className='os-reg mv2' onChange={e=> set_ocupacion(e)}/>
                </div>
              </div>

              <div className='flex flex-row'>
                <div className='flex flex-row items-center mr2'>
                  <Text className='pr2 os-bold'>Correo electrónico:</Text>
                  <EditableText value={email} placeholder='Correo electrónico' className='os-reg mv2' onChange={e=> set_email(e)}/>
                </div>
                
                
                </div>
            </div>
          
            <div className='w-50 flex flex-column'>
              <div className='flex flex-column w-100 mr1'>
                <Text className='os-bold'>Observaciones:</Text>
                <EditableText value={observaciones} placeholder='Observaciones' className='mv2 os-reg' multiline={true} minLines={8} maxLines={12} onChange={e=> 
                  set_observaciones(e)
                }/>
              </div>
              <div className='flex flex-column w-100 mr1'>
                <Text className='os-bold'>Fármacos:</Text>
                <EditableText value={farmacos} placeholder='Fármacos' className='mv2 os-reg' multiline={true} minLines={8} style={{maxHeight: 100}} onChange={e=> 
                  set_farmacos(e)
                }/>
              </div>
            </div>
          </div>


          <table className='w-100'>
            <colgroup>
              <col style={{width: 80}}/>
              <col style={{width: 200}}/>
              <col style={{width: 80}}/>
              <col style={{width: 200}}/>
            </colgroup>
            <tr>
              <td><Checkbox className='mr2 pt2' large label='Tabaco' checked={cb_tabaco} onChange={(e) => set_cb_tabaco(!cb_tabaco)} /></td>
              <td className='pr2'><EditableText confirmOnEnterKey multiline maxLines={1} placeholder='Pulsa para editar' value={tb_tabaco} onChange={e => set_tb_tabaco(e)} className='ml2 mv2'/></td>
              <td><Checkbox className='mr2 pt2' large label='Cir' checked={cb_cir} onChange={(e) => set_cb_cir(!cb_cir)} /></td>
              <td className='pr2'><EditableText confirmOnEnterKey multiline maxLines={1} placeholder='Pulsa para editar' value={tb_cir1} onChange={e => set_tb_cir1(e)} className='ml2 mv2'/></td>
            </tr>
            <tr>
              <td><Checkbox className='mr2 pt2' large label='Alcohol' checked={cb_alcohol} onChange={(e) => set_cb_alcohol(!cb_alcohol)} /></td>
              <td className='pr2'><EditableText confirmOnEnterKey multiline maxLines={1} placeholder='Pulsa para editar' value={tb_alcohol} onChange={e => set_tb_alcohol(e)} className='ml2 mv2'/></td>
              <td></td>
              <td className='pr2'><EditableText confirmOnEnterKey multiline maxLines={1} placeholder='Pulsa para editar' value={tb_cir2} onChange={e => set_tb_cir2(e)} className='ml2 mv2'/></td>
            </tr>
            <tr>
              <td><Checkbox className='mr2 pt2' large label='Alergias' checked={cb_alergias} onChange={(e) => set_cb_alergias(!cb_alergias)} /></td>
              <td className='pr2'><EditableText confirmOnEnterKey multiline maxLines={1} placeholder='Pulsa para editar' value={tb_alergias1} onChange={e => set_tb_alergias1(e)} className='ml2 mv2'/></td>
              <td></td>
              <td className='pr2'><EditableText confirmOnEnterKey multiline maxLines={1} placeholder='Pulsa para editar' value={tb_cir3} onChange={e => set_tb_cir3(e)} className='ml2 mv2'/></td>
            </tr>
            <tr>
              <td></td>
              <td className='pr2'><EditableText confirmOnEnterKey multiline maxLines={1} placeholder='Pulsa para editar' value={tb_alergias2} onChange={e => set_tb_alergias2(e)} className='ml2 mv2'/></td>
              <td></td>
              <td className='pr2'><EditableText confirmOnEnterKey multiline maxLines={1} placeholder='Pulsa para editar' value={tb_cir4} onChange={e => set_tb_cir4(e)} className='ml2 mv2'/></td>
            </tr>
            <tr>
              <td><Checkbox className='mr2 pt2' large label='HTA' checked={cb_hta} onChange={(e) => set_cb_hta(!cb_hta)} /></td>
              <td className='pr2'><EditableText confirmOnEnterKey multiline maxLines={1} placeholder='Pulsa para editar' value={tb_hta} onChange={e => set_tb_hta(e)} className='ml2 mv2'/></td>
              <td></td>
              <td className='pr2'><EditableText confirmOnEnterKey multiline maxLines={1} placeholder='Pulsa para editar' value={tb_cir5} onChange={e => set_tb_cir5(e)} className='ml2 mv2'/></td>
            </tr>
            <tr>
              <td><Checkbox className='mr2 pt2' large label='Dislipemia' checked={cb_dislipemia} onChange={(e) => set_cb_dislipemia(!cb_dislipemia)} /></td>
              <td className='pr2'><EditableText confirmOnEnterKey multiline maxLines={1} placeholder='Pulsa para editar' value={tb_dislipemia} onChange={e => set_tb_dislipemia(e)} className='ml2 mv2'/></td>
              <td><Checkbox className='mr2 pt2' large label='Antec. Médicos' checked={cb_antecmedicos} onChange={(e) => set_cb_antecmedicos(!cb_antecmedicos)} /></td>
              <td className='pr2'><EditableText confirmOnEnterKey multiline maxLines={1} placeholder='Pulsa para editar' value={tb_antecmedicos} onChange={e => set_tb_antecmedicos(e)} className='ml2 mv2'/></td>
            </tr>
            <tr>
              <td><Checkbox className='mr2 pt2' large label='DM' checked={cb_dm} onChange={(e) => set_cb_dm(!cb_dm)} /></td>
              <td className='pr2'><EditableText confirmOnEnterKey multiline maxLines={1} placeholder='Pulsa para editar' value={tb_dm} onChange={e => set_tb_dm(e)} className='ml2 mv2'/></td>
              <td></td>
              <td className='pr2'><EditableText confirmOnEnterKey multiline maxLines={1} placeholder='Pulsa para editar' value={tb_antecmedicos2} onChange={e => set_tb_antecmedicos2(e)} className='ml2 mv2'/></td>
            </tr>
            <tr>
              <td><Checkbox className='mr2 pt2' large label='Antec. Digestivos' checked={cb_antecdigestivos} onChange={(e) => set_cb_antecdigestivos(!cb_antecdigestivos)} /></td>
              <td className='pr2'><EditableText confirmOnEnterKey multiline maxLines={1} placeholder='Pulsa para editar' value={tb_antecdigestivos} onChange={e => set_tb_antecdigestivos(e)} className='ml2 mv2'/></td>
              <td></td>
              <td className='pr2'><EditableText confirmOnEnterKey multiline maxLines={1} placeholder='Pulsa para editar' value={tb_antecmedicos3} onChange={e => set_tb_antecmedicos3(e)} className='ml2 mv2'/></td>
            </tr>
            <tr>
              <td></td>
              <td className='pr2'><EditableText confirmOnEnterKey multiline maxLines={1} placeholder='Pulsa para editar' value={tb_antecdigestivos2} onChange={e => set_tb_antecdigestivos2(e)} className='ml2 mv2'/></td>
              <td></td>
              <td className='pr2'><EditableText confirmOnEnterKey multiline maxLines={1} placeholder='Pulsa para editar' value={tb_antecmedicos4} onChange={e => set_tb_antecmedicos4(e)} className='ml2 mv2'/></td>
            </tr>
            <tr>
              <td></td>
              <td className='pr2'><EditableText confirmOnEnterKey multiline maxLines={1} placeholder='Pulsa para editar' value={tb_antecdigestivos3} onChange={e => set_tb_antecdigestivos3(e)} className='ml2 mv2'/></td>
              <td></td>
              <td className='pr2'><EditableText confirmOnEnterKey multiline maxLines={1} placeholder='Pulsa para editar' value={tb_antecmedicos5} onChange={e => set_tb_antecmedicos5(e)} className='ml2 mv2'/></td>
            </tr>
          </table>

          
        <Text className='os-bold mt3 b underline'>Evolución anterior</Text>
        <EditableText value={notasAnteriores} placeholder='Sin evolución anterior' className='mv2 os-reg w-100' multiline={true} disabled={true}/>

        <Text className='os-bold b underline mt3'>Evolución</Text>
        {
          Object.keys(arrayNotas).filter((fechaNota) => {
            return fechaNota !== today
          }).map((key, index) => {
            let textoDeNota = arrayNotas[key];

            const dateArray = key.split('/')
            const day = dateArray[0];
            const month = dateArray[1];
            const year = dateArray[2];
            const d  = moment(`${day}-${month}-${year}`, "DD-MM-YYYY")

            return <div className='flex flex-column-l justify-center mt3' key={index}>
                <Text className='os-light mr3 i b'>{d.format('DD/MM/YYYY')}</Text>
                <EditableText placeholder='Error' multiline disabled value={typeof textoDeNota === typeof '' ? textoDeNota : textoDeNota.data} className='mv2 os-reg w-100 dark-gray ml3'></EditableText>
              </div>
          })
        }

        <div className='flex flex-column-l justify-center mt3'>
          <Text className='os-light mr3 i b gray mb1'>{moment().format('DD/MM/YYYY')} - (Hoy)</Text>
          <EditableText value={arrayNotas[today]} placeholder='Evolución' className='mv2 os-reg w-100 ml3' multiline={true} minLines={12} maxLines={12} onChange={e=>
            {
              let temp = Object.assign({}, arrayNotas);
              temp[today] = e;

              set_arrayNotas(temp);
            }
          }/>
        </div>

        <Button className='ml2' minimal large text='Guardar' rightIcon='floppy-disk' onClick={() => guardar()}/>
        <Button className='ml2 right-2 absolute' minimal large text='Borrar paciente' rightIcon='trash' onClick={() => setDialogBorrar(true)}/>
            <Dialog className='pa4' isOpen={dialogBorrar} onClose={() => { setDialogBorrar(false);}}>
                <div className="flex flex-column">
                    <h3>¿Estas seguro de que quieres borrar este paciente?</h3>
                    <div className="flex flex-row mt2">
                        <Button className='ml2' minimal large text='Cancelar' rightIcon='cross' onClick={() => setDialogBorrar(false)}/>
                        <Button className='ml2' minimal large text='Borrar paciente' rightIcon='trash' onClick={() => deletePaciente(id)}/>
                    </div>
                </div>

            </Dialog>
      </div>
    );
}

export default Form;
