import {createStore} from 'redux';

function loginReducer(state = null, action) {
    switch(action.type) {
        case 'SET':
            return action.data
        default:
            return state;
    }
}

const loginData = createStore(loginReducer);

export {loginData};