import { Button, InputGroup, Spinner, Tab, Tabs, Dialog, Text, Intent, Toaster, Position } from '@blueprintjs/core';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { loginData } from '../redux';
import Form from '../components/Form';
import * as _ from 'lodash';

let page = 0;

function Home() {

    const [pacientes, setPacientes] = useState([]);
    const [countPacientes, setCountPacientes] = useState(0);
    const [tabs, setTabs] = useState({});
    const [loading, setLoading] = useState(false);
    const [query, setQuery] = useState('');
    const [selectedTab, setSelectedTab] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [nombre, setNombre] = useState('');

    const fetchPacientes = async (query) => {
        setLoading(true);
        try {
            const result = await axios.get('https://backend.doctorurquijo.com/pacientes?_sort=nombre_busqueda:ASC' + (query ? '&nombre_busqueda_contains=' + query : ''), {
                headers: {
                    Authorization: 'Bearer ' + loginData.getState().jwt
                }
            })
            const count = await axios.get('https://backend.doctorurquijo.com/pacientes/count' + (query ? '?nombre_busqueda_contains=' + query : ''), {
                headers: {
                    Authorization: 'Bearer ' + loginData.getState().jwt
                }
            })
            page = result.data.length;
            setPacientes(result.data)
            setCountPacientes(count.data)
            setLoading(false);
        } catch (e) {
            setLoading(false);
            console.log(e)
        }
    }

    useEffect(() => {
        fetchPacientes()
    }, [])

    function open(paciente) {
        if (!tabs[paciente.id]) {
            tabs[paciente.id] = paciente.nombre;
            setTabs(tabs);
            setSelectedTab(`${paciente.id}`);
        }
    }

    function loadMore() {
        axios.get('https://backend.doctorurquijo.com/pacientes?nombre_busqueda_contains=' + _.deburr(query.toLowerCase()).trim() + '&_start=' + page + '&_limit=100&_sort=nombre_busqueda:ASC', {
            headers: {
                Authorization: 'Bearer ' + loginData.getState().jwt
            }
        }).then(result => {
            let p = pacientes;
            page += result.data.length;
            setLoading(false);
            console.log(p.concat(result.data))
            setPacientes(p.concat(result.data))
        }).catch(e => {
            setLoading(false);
            console.log(e)
        })
    }

    return (
        <div className='flex flex-column w-100 vh-100'>
            <div className='flex'>
                <div className='vh-100 flex flex-column outline' style={{ minWidth: 280 }}>
                    <div className="flex flex-row items-center justify-between pl2 pr1">
                        <div className="i center">
                            Pacientes (<span className="b">{countPacientes}</span>)
                        </div>
                        <Button outlined={true} icon="add" className='ma2' text='Añadir' onClick={() => setOpenDialog(true)} />
                    </div>
                    <InputGroup value={query} className='ma2' onChange={(e) => {

                        let query = e.target.value;


                        page = 0;
                        setLoading(true);
                        setQuery(query);
                        fetchPacientes(_.deburr(query).toLowerCase().trim())
                    }} leftIcon={'search'} />
                    {loading && <div className='flex flex-column items-center justify-center h-100'>
                        <Spinner />
                    </div>}
                    {!loading && <div className='flex flex-column' style={{ overflow: 'scroll' }}>
                        {pacientes.map((paciente) => paciente.nombre ? <a onClick={() => open(paciente)} key={paciente.id} className='pa2 outline'>{paciente.nombre.replace('Ã', 'Í')}</a> : '')}
                        <Button onClick={() => loadMore()} icon='refresh' text='Cargar más' className='ma2' />
                    </div>}
                </div>
                {Object.keys(tabs).length !== 0 && <div className='w-100 h-100'>
                    <div className='w-100 vh-100 pa3' style={{ overflow: 'scroll' }}>
                        <Tabs className='w-100 h-100' selectedTabId={selectedTab} onChange={(id) => { if (Object.keys(tabs) !== 1) setSelectedTab(id) }}>
                            {Object.keys(tabs).length !== 0 && Object.entries(tabs).map(entry => {
                                return <Tab id={entry[0]} title={entry[1]} panel={<Form afterDelete={() => {
                                    setSelectedTab(0);
                                    if (Object.keys(tabs).length === 0) {
                                        setTabs({});
                                    };
                                    let temp = tabs;
                                    delete temp[entry[0]];
                                    setTabs(temp);
                                    fetchPacientes(_.deburr(query).toLowerCase().trim())
                                }
                                } id={entry[0]} close={<Button text='Cerrar ficha' style={{ zIndex: 5000 }} onClick={() => {
                                    setSelectedTab(0);
                                    if (Object.keys(tabs).length === 0) {
                                        setTabs({});
                                    };
                                    let temp = tabs;
                                    delete temp[entry[0]];
                                    setTabs(temp);
                                }} icon={'cross'} className='ml2' />} />}>
                                </Tab>
                            })}
                        </Tabs>
                    </div>
                </div>}
                {tabs.length === 0 && <div className='w-100 h-100 flex items-center justify-center'>
                    <p>No hay elementos seleccionados</p>
                </div>}
            </div>
            <Dialog className='pa4' isOpen={openDialog} onClose={() => { setOpenDialog(false); setNombre('') }}>
                <Text>Nombre del paciente</Text>
                <InputGroup value={nombre} onChange={e => setNombre(e.target.value)} />
                <div className='flex'>
                    <Button className='mt2' text='Crear' intent={Intent.PRIMARY} onClick={() => {
                        setNombre('');
                        axios.post('https://backend.doctorurquijo.com/pacientes', {
                            nombre: nombre,
                            dni: '',
                            provincia: '',
                            email: '',
                            observaciones: '',
                            anteriorDocumento: '',
                            nonpos_edad: new Date(),
                            nonpos_asegSelect: 'PRIVADO',
                            nonpos_sexo: 'Hombre',
                            notasAnteriores: '',
                            farmacos: '',
                            ocupacion: '',
                            telefono: '',
                            cb_tabaco: false,
                            cb_alcohol: false,
                            cb_alergias: false,
                            cb_hta: false,
                            cb_diabetes: false,
                            cb_dislipemia: false,
                            cb_cir: false,
                            cb_antecdigestivos: false,
                            cb_antecmedicos: false,
                            tb_tabaco: '',
                            tb_alcohol: '',
                            tb_alergias1: '',
                            tb_alergias2: '',
                            tb_hta: '',
                            tb_diabetes: '',
                            tb_dislipemia: '',
                            tb_cir1: '',
                            tb_cir2: '',
                            tb_cir3: '',
                            tb_cir4: '',
                            tb_cir5: '',
                            tb_antecdigestivos: '',
                            tb_antecdigestivos2: '',
                            tb_antecdigestivos3: '',
                            tb_antecmedicos: '',
                            tb_antecmedicos2: '',
                            tb_antecmedicos3: '',
                            tb_antecmedicos4: '',
                            tb_antecmedicos5: '',
                            address: '',
                            alta: new Date(),
                            localidad: '',
                            tb_dm: '',
                            cb_dm: false,
                            arrayNotas: '',
                        }, {
                            headers: {
                                Authorization: 'Bearer ' + loginData.getState().jwt
                            }
                        }).then((res) => {
                            setOpenDialog(false);
                            Toaster.create({ position: Position.TOP }).show({ message: 'Paciente creado con éxito.', intent: Intent.SUCCESS })
                        }
                        ).catch(e => alert(e))
                    }} />
                    <Button className='mt2' text='Cancelar' onClick={() => { setOpenDialog(false); setNombre('') }} />
                </div>
            </Dialog>
        </div>
    );
}

export default Home;
