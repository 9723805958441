import { Button, InputGroup, Spinner } from '@blueprintjs/core';
import React, { useState } from 'react';
import axios from 'axios';
import { loginData } from '../redux';

function Login() {

    const [user, setUser] = useState('');
    const [pass, setPass] = useState('');
    const [loading, setLoading] = useState(false);
    
    if(loading)
        return(
            <div className='w-100 vh-100 flex items-center justify-center flex-column'>
                <Spinner/>
            </div>
        );

    return(
        <div className='w-100 vh-100 flex items-center justify-center flex-column'>
            <form onSubmit={async e => {
                e.preventDefault();
                setLoading(true);
                await axios.post('https://backend.doctorurquijo.com/auth/local', {
                    identifier: user,
                    password: pass
                }).then((res) => {
                    loginData.dispatch({type: 'SET', data: res.data});
                    setLoading(false);
                }).catch(() => {setLoading(false);alert('Usuario o contraseña incorrectos.')});
                
            }}>
                <InputGroup value={user} onChange={(e) => setUser(e.target.value)} placeholder='Usuario' className='mv2'/>
                <InputGroup value={pass} onChange={(e) => setPass(e.target.value)} type='password' placeholder='Contraseña' className='mv2'/>
                <Button fill type='submit' text='Iniciar sesión' className='mv2'/>
            </form>
        </div>
    );
}

export default Login;