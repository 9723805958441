import 'tachyons';
import React, { useEffect, useState } from 'react';
import { Button, Intent, Spinner } from "@blueprintjs/core";
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
import Login from './routes/Login';
import Home from './routes/Home';
import { loginData } from './redux';

function App() {

  const [userData, setUserData] = useState(null);

  loginData.subscribe(() => setUserData(loginData.getState()))
  /* useEffect([], () => {
  }); */

  return (
    <BrowserRouter>
      <Switch>
        {!userData && <Route exact path='/login' component={Login}/>}
        {userData && <Route path='/' component={Home}/>}
        <Route path='*' render={() => <Redirect to='/login'/>}/>
      </Switch>
      {/* <Spinner intent={Intent.PRIMARY} />
      <Button text='Reintentar'/> */}
    </BrowserRouter>
  );
}

export default App;
